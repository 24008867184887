var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.cardClosed ? _c('b-overlay', {
    attrs: {
      "variant": "white",
      "show": _vm.showLoading,
      "spinner-variant": "primary",
      "blur": "0",
      "opacity": ".75",
      "rounded": "sm"
    }
  }, [_c('b-card', _vm._g(_vm._b({
    ref: "bCard",
    style: _vm.cardStyles,
    attrs: {
      "no-body": "",
      "aria-expanded": !_vm.content_visible ? 'true' : 'false',
      "aria-controls": _vm.parentID
    }
  }, 'b-card', _vm.cardAttrs, false), _vm.$listeners), [_vm.showHeader ? _c('div', {
    staticClass: "card-header"
  }, [_c('div', [_c('b-row', [_c('b-col', [_vm.$attrs.kanban && !_vm.content_visible || !_vm.$attrs.kanban && _vm.$attrs.title ? _c('b-card-title', [_vm._v(" " + _vm._s(_vm.$attrs.title) + " "), _vm.$attrs.badgeSubHeading ? _c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$attrs.badgeSubHeading) + " ")]) : _vm._e()], 1) : _vm._e()], 1)], 1), _vm.$attrs['sub-title'] ? _c('b-card-sub-title', [_vm._v(" " + _vm._s(_vm.$attrs["sub-title"]) + " ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "text-right"
  }, [_c('b-card-title', [_vm._v(_vm._s(_vm.$attrs.currency) + " " + _vm._s(_vm.$attrs.amount) + " ")])], 1), _vm.showActions ? _c('b-card-actions-container', {
    attrs: {
      "available-actions": _vm.availableActions,
      "is-collapsed": !_vm.content_visible
    },
    on: {
      "collapse": _vm.triggerCollapse,
      "refresh": _vm.triggerRefresh,
      "close": _vm.triggerClose
    }
  }) : _vm._e()], 1) : _vm._e(), _c('b-collapse', {
    staticClass: "card-content",
    attrs: {
      "id": _vm.parentID,
      "visible": _vm.content_visible
    },
    model: {
      value: _vm.content_visible,
      callback: function callback($$v) {
        _vm.content_visible = $$v;
      },
      expression: "content_visible"
    }
  }, [_vm.$attrs['no-body'] !== undefined ? _vm._t("default") : _c('b-card-body', [_vm._t("default")], 2)], 2)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }